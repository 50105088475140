@charset "utf-8";
@import "../../node_modules/mixins-sass/src/mixins.scss";
@import "base.param.scss";
@import "sprite.scss";

// Presentation type homepage
//===========================
.presentation-hometype,.presentation-programtype
{
    height:                 100vh;
    position:               relative;
    width:                  100%;
    .gen__bg
    {
        display:                none;
    }
    .gen__videobg
    {
        display:                block;
    }
    .wrapper
    {
        @include calc(height , "100% - 30px");
        overflow:               hidden;
        position:               relative;
        width:                  100%;
        &:before,&::before
        {
            @include absoluteLeft(0,0);
            background: -moz-linear-gradient(top,  rgba(31,42,68,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6+ */
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(31,42,68,1)), color-stop(100%,rgba(255,255,255,0))); /* Chrome,Safari4+ */
            background: -webkit-linear-gradient(top,  rgba(31,42,68,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10+,Safari5.1+ */
            background: -o-linear-gradient(top,  rgba(31,42,68,1) 0%,rgba(255,255,255,0) 100%); /* Opera 11.10+ */
            background: -ms-linear-gradient(top,  rgba(31,42,68,1) 0%,rgba(255,255,255,0) 100%); /* IE10+ */
            background: linear-gradient(to bottom,  rgba(31,42,68,1) 0%,rgba(255,255,255,0) 100%); /* W3C */
            content:                " ";
            display:                block;
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1f2a44', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
            height:                 100%;
            @include opacity(.5);
            width:                  100%;
            z-index:                1;
            .ie9 &
            {
                background:             $blue-dark-01;
                @include opacity(.3);
            }
        }
    }
    .embed
    {
        @include absoluteLeft(0,0);
        height:                 100%;
        @include opacity(0);
        @include transitions(opacity 300ms $easeInOutCubic);
        width:                  100%;
        z-index:                1;
    }
    .features
    {
        height:                 100%;
        @include opacity(1);
        position:               relative;
        text-align:             center;
        @include transitions(opacity 300ms $easeInOutCubic);
        width:                  100%;
        z-index:                1;
        .subtitle
        {
            margin:             15px 0 25px;
        }
    }
    .buttons
    {
        a
        {
            &:first-child
            {
                margin:         0 15px 0 0;
            }
        }
    }
    .button-scroll
    {
        text-align:             center;
        @include absoluteLeft(50%,auto);
        bottom:                 0;
        @include translate(-50%,0);
        z-index:                1;
        .text
        {
            margin-bottom:          10px;
        }
        .icon
        {
            background:             $blue-dark-01;
            color:                  $white;
            display:                block;
            font-size:              18px;
            height:                 45px;
            left:                   50%;
            line-height:            45px;
            margin:                 0 0 0 -22.5px;
            position:               relative;
            width:                  45px;
            .icon-arrow-bottom
            {
                display:                block;
                line-height:            inherit;
                @include doAnim(scrollDown,1500ms,$easeInOutQuad,0s,infinite);
            }
        }
    }
    &.is--playing
    {
        .embed
        {
            @include opacity(1);
        }
        .features
        {
            @include opacity(0);
            pointer-events:         none;
        }
        .button-scroll
        {
            visibility:             hidden;
        }
    }
}

// Presentation type program
//==========================
.presentation-programtype
{
    height:                 auto;
    margin-bottom:          50px;
    .wrapper
    {
        @include calc(height , "100vh - 30px");
    }
    .preview
    {
        display:                block;
        height:                 45vh;
        margin:                 -23vh auto -100px;
        position:               relative;
        @include transitions(opacity 300ms $easeInOutCubic);
        width:                  auto;
        z-index:                1;
        .visual
        {
            display:                block;
            height:                 100%;
            margin:                 0 auto;
            max-width:              720px;
        }
        .gen__button--play
        {
            @include absoluteLeft(50%,50%);
            display:                block;
            margin:                 0 auto;
            @include translate(-50%,-50%);
            z-index:                1;
            .icon
            {
                &:after,&::after
                {
                    left:               7px;
                }
            }
        }
    }
    &.is--playing
    {
        .preview
        {
            @include opacity(0);
            pointer-events:         none;
        }
    }
}

// Blog landing
//=============
.presentation-blogtype,.presentation-articletype
{
    @include calc(height , "100vh - 105px");
    margin:                 0 0 $blog-filter-height;
    width:                  100%;
    .wrapper
    {
        height:                 100%;
        overflow:               hidden;
        position:               relative;
        &:before,&::before
        {
            @include absoluteLeft(0,0);
            background: -moz-linear-gradient(top,  rgba(31,42,68,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6+ */
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(31,42,68,1)), color-stop(100%,rgba(255,255,255,0))); /* Chrome,Safari4+ */
            background: -webkit-linear-gradient(top,  rgba(31,42,68,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10+,Safari5.1+ */
            background: -o-linear-gradient(top,  rgba(31,42,68,1) 0%,rgba(255,255,255,0) 100%); /* Opera 11.10+ */
            background: -ms-linear-gradient(top,  rgba(31,42,68,1) 0%,rgba(255,255,255,0) 100%); /* IE10+ */
            background: linear-gradient(to bottom,  rgba(31,42,68,1) 0%,rgba(255,255,255,0) 100%); /* W3C */
            content:                " ";
            display:                block;
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1f2a44', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
            height:                 100%;
            @include opacity(.5);
            pointer-events:         none;
            width:                  100%;
            z-index:                1;
            .ie9 &
            {
                background:             $blue-dark-01;
                @include opacity(.3);
            }
        }
    }
    .item
    {
        display:                block;
        float:                  left;
        height:                 100%;
        overflow:               hidden;
        position:               relative;
        @include transitions(width 400ms $easeInOutQuad);
        width:                  33.33%;
        .button
        {
            @include absoluteLeft(50%,auto);
            bottom:                 40px;
            color:                  $white;
            font-size:              45px;
            line-height:            .7;
            margin:                 0 0 0 -22.5px;
            @include opacity(0);
        }
        .features
        {
            @include absoluteLeft(0,auto);
            @include box-sizing(border-box);
            bottom:                 0;
            padding:                0 50px 90px;
            text-align:             center;
            width:                  100%;
            z-index:                1;
            .category
            {
                color:                  $white;
                display:                block;
                font:                   normal 1.2em $simplo;
                letter-spacing:         1.5px;
                text-transform:         uppercase;
            }
            .title
            {
                font-size:              2.4em;
                margin:                 25px auto 0;
                width:                  300px;
            }
            &:after,&::after
            {
                @include absoluteLeft(0,auto);
                background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6+ */
                background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0.65))); /* Chrome,Safari4+ */
                background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10+,Safari5.1+ */
                background: -o-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Opera 11.10+ */
                background: -ms-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* IE10+ */
                background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
                bottom:                 0;
                content:                " ";
                display:                block;
                height:                 200%;
                @include opacity(1);
                pointer-events:         none;
                @include transitions(opacity 1000ms $easeOutCubic);
                width:                  100%;
                z-index:                -1;
                .ie9 &
                {
                    background:             $black;
                    height:                 100%;
                    @include opacity(.1);
                    padding:                50px 0 0;
                }
            }
        }
        &.is--enabled
        {
            width:              40% !important;
        }
        &.is--disabled
        {
            width:              30%;
        }
        &:hover
        {
            .button
            {
                @include doAnim(moveArrowBlog,800ms,$easeOutExpo,0s,1,forwards);
            }
            .features
            {
                &:after,&::after
                {
                    @include opacity(.6);
                    .ie9 &
                    {
                        @include opacity(.6);
                    }
                }
            }
        }
    }
}

// ARTICLE TYPE
//=============
.presentation-articletype
{
    @include calc(height , "100vh - 250px");
    @include box-sizing(border-box);
    margin:                 0 0 0;
    padding:                0 0 50px;
    .wrapper
    {
        overflow:               visible;
    }
    .gen__share
    {
        @include absoluteLeft(50%,auto);
        bottom:             0;
        @include translate(-50%,0);
    }
    .pagination
    {
        @include absoluteRight(0,50%);
        height:                 200px;
        @include translate(0,-50%);
        width:                  320px;
        z-index:                2;
        .button
        {
            display:                block;
            height:                 50%;
            margin:                 0 0 2px;
            position:               relative;
            text-align:             center;
            width:                  100%;
            &.is--previous
            {
                .icon
                {
                    @include absoluteRight(0,auto);
                    bottom:                 0;
                }
            }
            &.is--next
            {
                .icon
                {
                    @include absoluteRight(0,0);
                }
            }
            .description
            {
                @include absoluteLeft(0,0);
                background:             $blue-light-01;
                height:                 100%;
                @include translate(100%,0);
                @include transitions(all 700ms $easeOutQuart);
                @include transition-delay(100ms);
                width:                  100%;
                .nested
                {
                    @include box-sizing(border-box);
                    display:                block;
                    height:                 100px;
                    padding:                15px 15px;
                    overflow:               hidden;
                    text-align:             left;
                    width:                  220px;
                    .gen__subtitle--big
                    {
                        letter-spacing:         0;
                    }
                    .subtitle
                    {
                        color:                  #a0c4f9;

                    }
                }
                .visual
                {
                    display:                block;
                    float:                  left;
                    height:                 100px;
                    width:                  100px;
                }
            }
            .icon
            {
                background:         $blue-dark-01;
                color:              $white;
                display:            block;
                font-size:          20px;
                height:             45px;
                line-height:        45px;
                @include transitions(all 500ms $easeOutQuart);
                width:              45px;
            }
            &:hover
            {
                .icon
                {
                    @include translate(100%,0);
                }
                .description
                {
                    @include translate(0,0);
                }
            }
        }
    }
}

// DUMMY
//======
.presentation-dummytype
{
    height:                 35vh;
    min-height:             340px;
    .wrapper
    {
        &:before,&::before
        {
            display:            none !important;
        }
    }
    .pagination
    {
        bottom:                 0;
        top:                    auto;
        @include translate(0,0);
    }
}


// STARTUP
//========
.presentation-startuptype
{
    background:             url($COMP_IMG_COMMON+'startup/background.jpg') center center no-repeat #e6e6e6;
    // background:                 #f2f2f2;
    @include calc(height , "100vh - 255px");
    margin:                 0 0 $blog-filter-height + 50;
    position:               relative;
    width:                  100%;
    .wrapper
    {
        @include absoluteLeft(50%,auto);
        bottom:                 100px;
        margin:                 0 0 0 -30vw;
        text-align:             center;
        .features
        {
            list-style:             none;
            margin:                 50px 0 0;
            overflow:               hidden;
            width:                  100%;
            li
            {
                display:                block;
                float:                  left;
                text-align:             left;
                width:                  33.33%;
            }
        }
        .number
        {
            color:                      $blue-light-01;
            display:                    block;
            float:                      left;
            font:                       600 5em $simplo;
            text-transform:             uppercase;

        }
        .text
        {
            @include box-sizing(border-box);
            color:                      $blue-dark-01;
            display:                    block;
            font:                       normal 1.4em $simplo;
            letter-spacing:             1.5px;
            padding:                    5px 20px 0;
            overflow:                   hidden;
            text-transform:             uppercase;
        }
        .title
        {
            color:                  $blue-dark-01;
        }
    }
}

// TEAM
//=====
.presentation-teamtype
{
    height:                 570px;
    margin:                 0 0 0;
    position:               relative;
    width:                  100%;
    .gen__description--big
    {
        color:                  $grey-04;
    }
    .wrapper
    {
        @include absoluteLeft(50%,50%);
        margin:                 0 0 0;
        text-align:             center;
        @include translate(-50%,-50%);
        .title
        {
            color:                  $blue-dark-01;
        }
    }
}