@charset "utf-8";
@import "../../node_modules/mixins-sass/src/mixins.scss";
@import "base.param.scss";
@import "sprite.scss";

// SLIDESHOW
//==========
.slideshow
{
    height:                 600px;
    position:               relative;
    width:                  100%;
    .brand
    {
        padding:                20px 0 0;
        position:               relative;
        text-align:             center;
        z-index:                1;
    }
    .icon
    {
        color:                  $white;
        font-size:              30px;
        margin:                 25px 0 0;
        position:               relative;
        text-align:             center;
        z-index:                1;
    }
}

// Generic slideshow
//==================
.slideshow__collection {
    @include absoluteLeft(0,0);
    height:                 100%;
    width:                  100%;
    z-index:                0;
    .slide {
        @include absoluteLeft(0,0);
        height:                 100%;
        width:                  100%;
        .dp-t
        {
            height:                 100%;
            width:                  100%;
        }
        .features
        {
            @include absoluteLeft(0,0);
            @include box-sizing(border-box);
            height:                 100%;
            padding:                0 0 0 100px;
            width:                  50%;
            z-index:                2;
            .title
            {
                @include opacity(0);
                @include translate(-50px,0);
                @include transitions(opacity 600ms $easeOutCubic,transform 1000ms $easeOutExpo);
            }
            .notes
            {
                list-style:             none;
                margin:                 20px 0 0;
                @include opacity(0);
                overflow:               hidden;
                @include transitions(opacity 600ms $easeOutCubic,transform 1000ms $easeOutExpo);
                @include translate(-50px,0);
                @include transition-delay(100ms);
                li
                {
                    color:                  $white;
                    font:                   normal 1.4em/1.3 $simplo;
                    letter-spacing:         0;
                    margin:                 0 0 3px;
                    text-transform:         uppercase;
                    &:before,&::before
                    {
                        color:                  $white;
                        content:                "•";
                        display:                block;
                        float:                  left;
                        margin-right:           10px;
                    }
                }
            }
        }
        &.disabled {
            visibility:             hidden;
        }
        &.enabled {
            visibility:             visible;
        }
        &.active
        {
            .title,.notes
            {
                @include opacity(1);
                @include translate(0,0);
            }
        }
        &:before,&::before
        {
            @include absoluteLeft(0,0);
            background: -moz-linear-gradient(left,  rgba(31,42,68,1) 0%, rgba(0,0,0,0) 100%); /* FF3.6+ */
            background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(31,42,68,1)), color-stop(100%,rgba(0,0,0,0))); /* Chrome,Safari4+ */
            background: -webkit-linear-gradient(left,  rgba(31,42,68,1) 0%,rgba(0,0,0,0) 100%); /* Chrome10+,Safari5.1+ */
            background: -o-linear-gradient(left,  rgba(31,42,68,1) 0%,rgba(0,0,0,0) 100%); /* Opera 11.10+ */
            background: -ms-linear-gradient(left,  rgba(31,42,68,1) 0%,rgba(0,0,0,0) 100%); /* IE10+ */
            background: linear-gradient(to right,  rgba(31,42,68,1) 0%,rgba(0,0,0,0) 100%); /* W3C */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1f2a44', endColorstr='#00000000',GradientType=1 ); /* IE6-9 */
            content:                " ";
            display:                block;
            height:                 100%;
            width:                  50%;
            z-index:                1;
            .layout__blog &
            {
                display:                none;
            }
        }
    }
}

// controls
//=========
.slideshow__controls
{
    @include absoluteLeft(0,auto);
    bottom:                 10px;
    text-align:             center;
    width:                  100%;
    z-index:                1;
    .button
    {
        color:                  $white;
        font-size:              14px;
    }
    .triggers
    {
        @include inlineBlock;
        list-style:             none;
        li
        {
            cursor:                 pointer;
            height:                 12px;
            @include inlineBlock;
            margin:                 0 8px;
            position:               relative;
            top:                    0;
            width:                  12px;
            span
            {
                background:             $white;
                @include border-radius(6px);
                display:                block;
                height:                 50%;
                left:                   3px;
                position:               relative;
                top:                    3px;
                width:                  50%;
            }
            &:after,&::after
            {
                @include absoluteLeft(50%,50%);
                border:                 1px solid $white;
                @include border-radius(12px);
                content:                " ";
                display:                block;
                height:                 12px;
                margin:                 -7px 0 0 -7px;
                @include scale(0);
                @include transitions(all 500ms $easeOutExpo);
                width:                  12px;

            }
            &.active
            {
                &:after,&::after
                {
                    @include scale(1);
                }
            }
        }
    }
}