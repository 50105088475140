@font-face {
    font-family: 'icomoon';
    src:url('../font/icomoon.eot?-tyfaih');
    src:url('../font/icomoon.eot?#iefix-tyfaih') format('embedded-opentype'),
        url('../font/icomoon.woff?-tyfaih') format('woff'),
        url('../font/icomoon.ttf?-tyfaih') format('truetype'),
        url('../font/icomoon.svg?-tyfaih#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-angellist:before {
    content: "\e60d";
}

.icon-arrow-share:before {
    content: "\e60e";
}

.icon-logo-h1:before {
    content: "\e608";
}

.icon-arrow-right:before {
    content: "\e60a";
}

.icon-arrow-left:before {
    content: "\e605";
}

.icon-arrow-blog:before {
    content: "\e606";
}

.icon-arrow-bottom:before {
    content: "\e607";
}

.icon-grid:before {
    content: "\e60f";
}

.icon-close:before {
    content: "\e610";
}

.icon-play2:before {
    content: "\e609";
}

.icon-social-twitter:before {
    content: "\e601";
}

.icon-social-linkedin:before {
    content: "\e602";
}

.icon-social-facebook:before {
    content: "\e603";
}

.icon-location:before {
    content: "\e60b";
}

.icon-play:before {
    content: "\e604";
}

.icon-mail:before {
    content: "\e611";
}

.icon-google:before {
    content: "\e612";
}

.icon-instagram:before {
    content: "\e60c";
}

.icon-sina-weibo:before {
    content: "\e600";
}

