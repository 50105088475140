@charset "utf-8";
@import "../../node_modules/mixins-sass/src/mixins.scss";
@import "base.param.scss";
@import "sprite.scss";

@include keyframes(scrollDown)
{
    0%
    {
        @include opacity(1);
        @include translate(0,0);
    }
    50%
    {
        @include opacity(0);
        @include translate(0,20px);
    }
    51%
    {
        @include opacity(0);
        @include translate(0,-20px);
    }
    100%
    {
        @include opacity(1);
        @include translate(0,0);
    }
}

@include keyframes(moveArrow)
{
    0%
    {
        @include opacity(1);
        @include translate(0,0);
    }
    50%
    {
        @include opacity(0);
        @include translate(50px,0);
    }
    51%
    {
        @include opacity(0);
        @include translate(-50px,0);
    }
    100%
    {
        @include opacity(1);
        @include translate(10px,0);
    }
}

@include keyframes(moveArrowBlog)
{
    0%
    {
        @include opacity(0);
        @include translate(-50px,0);
    }
    100%
    {
        @include opacity(1);
        @include translate(0,0);
    }
}

@include keyframes(sticky)
{
    0%
    {
        top:                -200px;
    }
    100%
    {
        top:                0;
    }
}