@charset "utf-8";
@import "../../node_modules/mixins-sass/src/mixins.scss";
@import "base.param.scss";
@import "sprite.scss";

.layout__startup
{
    .hardware-grid
    {
        margin-bottom:          -80px;
    }
}


// FILTER
//=======
.filter-startup
{
    @include absoluteLeft(0,0);
    height:                 $blog-filter-height + 50;
    @include calc(top , "100vh - 255px");
    overflow:               hidden;
    @include transitions(height 500ms $easeOutCubic);
    width:                  100%;
    z-index:                5;
    .buttons
    {
        background:             $blue-light-01;
        color:                  $white;
        font:                   normal 1.4em $simplo;
        @include inlineBlock;
        height:                 $blog-filter-height;
        letter-spacing:         1.5px;
        line-height:            $blog-filter-height;
        list-style:             none;
        text-align:             center;
        text-transform:         uppercase;
        width:                  100%;
        li
        {
            @include inlineBlock;
            margin:             0 65px 0 0;
            position:           relative;
            a
            {
                color:              #9fc3fb;
            }
            &:after,&::after
            {
                @include absoluteLeft(50%,auto);
                bottom:             0;
                border-style:       solid;
                border-width:       0 5px 5px 5px;
                border-color:       transparent transparent #ffffff transparent;
                content:            " ";
                display:            block;
                height:             0;
                margin:             0 0 0 -5px;
                @include scale(0);
                @include transitions(all 200ms $easeOutCubic);
                @include transform-origin(50% 100%);
                width:              0;
            }
            &.active
            {
                a
                {
                    color:              $white;
                }
                &:after,&::after
                {
                    @include scale(1);
                }
            }
        }
    }
    .collection
    {
        background:             $white;
        height:                 50px;
        position:               relative;
        width:                  100%;
        .item
        {
            @include absoluteLeft(0,0);
            display:                block;
            height:                 100%;
            line-height:            55px;
            @include opacity(0);
            text-align:             center;
            @include transitions(opacity 300ms $easeOutCubic);
            pointer-events:         none;
            @include transition-delay(100ms);
            width:                  100%;
            .button
            {
                color:                  $blue-dark-01;
                font:                   normal 1.4em $simplo;
                @include inlineBlock;
                height:                 $blog-filter-height;
                letter-spacing:         1.5px;
                margin:                 0 15px;
                text-transform:         uppercase;
                @include transitions(color 200ms $easeOutCubic);
                &.active
                {
                    color:                  $blue-light-01;
                }
            }
            &.active
            {
                @include opacity(1);
                pointer-events:         auto;
            }
        }
    }
    &.is--sticky
    {
        position:               fixed;
        top:                    0;
        @include doAnim(sticky,800ms,$easeOutExpo,0s,1,forwards);
    }
}


// COLLECTION
//===========
.startup-collection
{
    position:               relative;
    width:                  100%;
    .item
    {
        display:                block;
        height:                 60vh;
        max-height:             600px;
        position:               relative;
        text-align:             center;
        width:                  100%;
        .gen__bg
        {
            background-attachment:          fixed;
        }
        .features
        {
            height:                 100%;
            position:               relative;
            width:                  100%;
            z-index:                2;
            .dp-t
            {
                height:                 100%;
                width:                  100%;
            }
            .tagline
            {
                color:                  $white;
                font:                   normal 2em $simplo;
                letter-spacing:         1.5px;
                margin:                 25px 0;
                text-transform:         uppercase;
            }
        }
        &:after,&::after
        {
            @include absoluteLeft(0,0);
            background:             $blue-dark-01;
            content:                " ";
            display:                block;
            height:                 100%;
            @include opacity(.2);
            width:                  100%;
            z-index:                1;
        }
    }
}