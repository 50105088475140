@charset "utf-8";
@import "../../node_modules/mixins-sass/src/mixins.scss";
@import "base.param.scss";
@import "sprite.scss";

// TIMELINE
//=========
.timeline
{
    background:             $white;
    .wrapper
    {
        padding:                100px 0;
        text-align:             center;
    }
    .brand
    {
        color:                  $blue-dark-01;
    }
}

.timeline__steps
{
    list-style:                 none;
    margin:                     75px auto 0;
    position:                   relative;
    width:                      auto;
    li
    {
        cursor:                 pointer;
        height:                 70px;
        @include inlineBlock;
        margin:                 0 55px;
        position:               relative;
        width:                  70px;
        &:after
        {
            @include absoluteLeft(80px,35px);
            background:             $grey-02;
            content:                " ";
            display:                block;
            height:                 3px;
            width:                  90px;
        }
        &:last-child
        {
            &:after
            {
                display:                none;
            }
        }
        .icon
        {
            display:                block;
            height:                 100%;
            @include scale(1);
            @include transitions(all 500ms $easeOutExpo);
            width:                  100%;
            span
            {
                @include absoluteLeft(50%,50%);
                background:             $grey-02;
                @include border-radius(30px);
                display:                block;
                height:                 30px;
                margin:                 -15px 0 0 -15px;
                @include transitions(all 300ms $easeOutCubic);
                width:                  30px;
                z-index:                1;
            }
            &:before,&::before
            {
                @include absoluteLeft(0,0);
                background: rgb(67,148,248); /* Old browsers */
                background: -moz-linear-gradient(left,  rgba(67,148,248,1) 0%, rgba(31,42,68,1) 100%); /* FF3.6+ */
                background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(67,148,248,1)), color-stop(100%,rgba(31,42,68,1))); /* Chrome,Safari4+ */
                background: -webkit-linear-gradient(left,  rgba(67,148,248,1) 0%,rgba(31,42,68,1) 100%); /* Chrome10+,Safari5.1+ */
                background: -o-linear-gradient(left,  rgba(67,148,248,1) 0%,rgba(31,42,68,1) 100%); /* Opera 11.10+ */
                background: -ms-linear-gradient(left,  rgba(67,148,248,1) 0%,rgba(31,42,68,1) 100%); /* IE10+ */
                background: linear-gradient(to right,  rgba(67,148,248,1) 0%,rgba(31,42,68,1) 100%); /* W3C */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4394f8', endColorstr='#1f2a44',GradientType=1 ); /* IE6-9 */
                @include border-radius(70px);
                content:                " ";
                display:                block;
                height:                 100%;
                width:                  100%;
            }
            &:after,&::after
            {
                @include absoluteLeft(4px,3px);
                background:             $white;
                content:                " ";
                display:                block;
                @include border-radius(70px);
                @include calc(height , "100% - 6px");
                @include calc(width , "100% - 8px");
            }
        }
        .number
        {
            @include absoluteLeft(0,0);
            color:                  $blue-dark-01;
            font:                   normal 9em/.7 $open;
            @include opacity(0);
            @include transitions(opacity 500ms $easeOutCubic);
            width:                  100%;

        }
        &:hover
        {
            .icon
            {
                span
                {
                    background:             #d3d1d2;
                }
            }
        }
        &.active
        {
            .icon
            {
                @include scale(0);
            }
            .number
            {
                @include opacity(1);
            }
        }
    }
}

// Slideshow
.timeline__collection
{
    margin:                 50px auto 0;
    overflow:               hidden;
    @include opacity(1);
    position:               relative;
    @include transitions(opacity 500ms $easeOutCubic);
    width:                  $timeline-width;
    .nested
    {
        position:               relative;
        width:                  $timeline-width * 5;
    }
    &.disabled
    {
        @include opacity(0);
        pointer-events:         none;
    }
    .slide {
        display:                block;
        float:                  left;
        width:                  $timeline-width;
        .embed
        {
            @include absoluteLeft(0,0);
            display:                block;
            height:                 100%;
            @include opacity(0);
            pointer-events:         none;
            width:                  100%;
        }
        .title
        {
            color:                  $blue-dark-01;
            font:                   normal 1.8em $simplo;
            letter-spacing:         1.5px;
            margin:                 0 0 25px;
            text-transform:         uppercase;
        }
        .visual-context
        {
            display:                block;
            margin:                 0 0 25px;
            position:               relative;
            .gen__button--play
            {
                @include absoluteLeft(50%,50%);
                margin:                 (-($button-play-size/2)) 0 0 (-($button-play-size/2));
                .icon
                {
                    left:                   5px;
                }
            }
            .visual
            {
                display:                block;
                @include opacity(1);
                @include transitions(opacity 300ms $easeOutCubic);
                width:                  auto;
            }
        }
        &.active
        {
            .visual
            {
                @include opacity(0);
            }
            .embed
            {
                @include opacity(1);
                pointer-events:             auto;
            }
        }
    }
}