@charset "utf-8";
@import "../../node_modules/mixins-sass/src/mixins.scss";
@import "base.param.scss";
@import "sprite.scss";

.layout__team
{
    background:             url($COMP_IMG_COMMON+'team/background.jpg') center 0 no-repeat #e6e6e6;
    .hardware-grid
    {
        margin-bottom:          -80px;
    }
    &.has--noBg
    {
        background:         #f2f2f2;
    }
}

// FILTER
//=======
.filter-team
{
    height:                 100px;
    overflow:               hidden;
    width:                  100%;
    .buttons
    {
        @include inlineBlock;
        list-style:             none;
        text-align:             center;
        width:                  100%;
        li
        {
            @include inlineBlock;
            margin:             0 5px;
            position:           relative;
            &.active
            {
                .gen__button--cta
                {
                    color:                  $white;
                    .text
                    {
                        @include translate(0,5px);
                    }
                    &:after
                    {
                        top:                0;
                    }
                }
            }
        }
    }
    .collection
    {
        height:                 50px;
        position:               relative;
        width:                  100%;
        .item
        {
            @include absoluteLeft(0,0);
            display:                block;
            height:                 100%;
            line-height:            55px;
            @include opacity(0);
            text-align:             center;
            @include transitions(opacity 300ms $easeOutCubic);
            pointer-events:         none;
            @include transition-delay(100ms);
            width:                  100%;
            .button
            {
                color:                  $blue-dark-01;
                font:                   normal 1.4em $simplo;
                @include inlineBlock;
                height:                 $blog-filter-height;
                letter-spacing:         1.5px;
                margin:                 0 0;
                text-transform:         uppercase;
                @include transitions(color 200ms $easeOutCubic);
                &:after,&::after
                {
                    color:                  $blue-dark-01;
                    content:                "●";
                    @include inlineBlock;
                    font-size:              10px;
                    margin:                 0 5px 0 6px;
                    position:               relative;
                    top:                    -2px;

                }
                &:last-child
                {
                    &:after,&::after
                    {
                        display:                none;
                    }
                }
                &.active
                {
                    color:                  $blue-light-01;
                }
            }
            &.active
            {
                @include opacity(1);
                pointer-events:         auto;
            }
        }
    }
    &.is--sticky
    {
        position:               fixed;
        top:                    0;
        @include doAnim(sticky,800ms,$easeOutExpo,0s,1,forwards);
    }
}

// COLLECTION
//===========
.team-collection
{
    @include box-sizing(border-box);
    margin:                 0 auto;
    overflow:               hidden;
    padding:                0 $team-grid-gutter*3 0 0;
    width:                  100%;
}

.team-collection__item
{
    @include box-sizing(border-box);
    display:                block;
    float:                  left;
    margin:                 0 0 $team-grid-gutter;
    padding:                0;
    position:               relative;
    text-align:             center;
    width:                  33.33%;

    &:nth-child(3n+2)
    {
        right:              -$team-grid-gutter;

    }
    &:nth-child(3n+3)
    {
        right:              -$team-grid-gutter*2;
    }
}

// RELATED
//========.
.team-related
{
    background:             #e6e6e6;
    @include box-sizing(border-box);
    margin:                 80px auto 0;
    padding:                70px 0;
    position:               relative;
    .gen__context
    {
        position:               relative;
        min-width:              730px;
    }
    .slideshow__collection
    {
        height:                 400px;
        margin:                 50px auto 0;
        overflow:               hidden;
        position:               relative;
        width:                  730px;
        .content
        {
            height:                 100%;
            position:               relative;
            width:                  9999px;
        }
        .slide
        {
            float:                  left;
            margin:                 0 30px 0 0;
            position:               relative;
            width:                  350px;
            .team-collection__item
            {
                height:                 100%;
                width:                  100%;
                .visual-context
                {
                    height:                 350px;
                }
            }
            &:before,&::before
            {
                display:                none;
            }
            &:last-child
            {
                margin:                 0;
            }
        }
    }
    .slideshow__controls
    {
        bottom:                 auto;
        height:                 45px;
        pointer-events:         none;
        top:                    260px;
        .button
        {
            background:             $blue-dark-01;
            display:                block;
            font-size:              20px;
            height:                 45px;
            line-height:            45px;
            position:               absolute;
            pointer-events:         auto;
            @include transitions(all 300ms $easeOutCubic);
            top:                    0;
            width:                  45px;
            &:first-child
            {
                left:                   0;
            }
            &:last-child
            {
                right:                  0;
            }
            &:hover
            {
                background:             $blue-light-01;
            }
            &.disabled
            {
                @include opacity(.1);
                pointer-events:         none;
            }
        }
    }
    .title
    {
        color:                  $blue-dark-01;
        text-align:             center;
    }
}