@charset "utf-8";
@import "../../node_modules/mixins-sass/src/mixins.scss";
@import "base.param.scss";
@import "sprite.scss";

// ALUMNI GRID
//============
.alumni-grid
{
    margin:                 0 auto;
    width:                  100%;
    &:after
    {
        clear:                  both;
        content:                "";
        display:                block;
    }
}
.alumni-grid__column
{
    background:             $blue-dark-01;
    display:                block;
    float:                  left;
    width:                  25%;
    &.is--small
    {
        width:                  12.5%;
    }
}

.alumni-grid__item
{
    display:                block;
    float:                  left;
    padding-bottom:         50%;
    position:               relative;
    width:                  50%;
    &:before,&::before
    {
        @include absoluteLeft(0,0);
        background:             $blue-dark-01;
        content:                " ";
        display:                block;
        height:                 100%;
        @include opacity(0);
        pointer-events:         none;
        @include transitions(opacity 500ms $easeOutCubic);
        width:                  100%;
        z-index:                1;
    }
    .context
    {
        @include absoluteLeft(0,0);
        display:                block;
        height:                 100%;
        overflow:               hidden;
        width:                  100%;
        z-index:                1;
    }
    .visual
    {
        @include absoluteLeft(0,0);
        display:                block;
        height:                 100%;
        width:                  100%;
        z-index:                0;
    }
    .gen__centerContext
    {
        @include perspective(1000px);
    }
    .features
    {
        display:                table;
        height:                 100%;
        text-align:             center;
        width:                  100%;
        .title
        {
            color:                  $white;
            font:                   normal 1.4em $simplo;
            letter-spacing:         .25px;
            @include opacity(0);
            text-transform:         uppercase;
            @include translate3d(0,0,50px);
            @include transitions(opacity 500ms $easeOutCubic , transform 800ms $easeOutQuad);
            .safari &
            {
                @include transitions(opacity 500ms $easeOutCubic , -webkit-transform 800ms $easeOutQuad);
            }
        }
        .subtitle
        {
            color:                  $white;
            font:                   normal 1.2em $simplo;
            margin:                 3px 0 5px;
            @include opacity(0);
            text-transform:         uppercase;
            @include translate3d(0,0,50px);
            @include transitions(opacity 500ms $easeOutCubic , transform 800ms $easeOutQuad);
            @include transition-delay(50ms);
            .safari &
            {
                @include transitions(opacity 500ms $easeOutCubic , transform 800ms $easeOutQuad);
            }
        }
        .icon
        {
            color:                  $white;
            font-size:              50px;
            line-height:            .5;
            @include opacity(0);
            @include translate(-10%,0);
            @include transitions(opacity 500ms $easeOutCubic , transform 800ms $easeOutExpo);
            @include transition-delay(100ms);
            .safari &
            {
                @include transitions(opacity 500ms $easeOutCubic , -webkit-transform 800ms $easeOutExpo);
            }
        }
    }
    .is--small &
    {
        padding-bottom:         100%;
        width:                  100%;
    }
    &.is--big
    {
        padding-bottom:     100%;
        width:              100%;
    }
    &.is--vertical
    {
        height:             200%;
        padding-bottom:     200%;
        width:              100%;
    }
    &.is--horizontal
    {
        width:              100%;
    }
    &:hover
    {
        .features
        {
            .title,.subtitle
            {
                @include opacity(1);
                @include translate3d(0,0,0);
            }
            .icon
            {
                @include opacity(1);
                @include translate(0,0);
            }
        }
        &:before,&::before
        {
            @include opacity(.5);
        }
    }

}


// SMALL SCREENS
//==============
@media only screen and (max-width : 980px)
{
    .alumni-grid__column
    {
        width:                  50%;
        &.is--small
        {
            width:                  25%;
        }
        &.is--separator
        {
            clear:                  both;
        }
    }
}
