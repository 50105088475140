@charset "utf-8";
@import "../../node_modules/mixins-sass/src/mixins.scss";
@import "base.param.scss";
@import "sprite.scss";

// INTRODUCTION
//=============
.introduction
{
    background:             $white;
    &.is--alternative
    {
        background:             $grey-03;
    }
    .wrapper
    {
        padding:                100px 0;
        text-align:             center;
    }
    .gen__description--big
    {
        padding:                0 10%;
    }
    .title
    {
        color:                  $blue-dark-01;
    }
    .gen__button--contextual,.gen__button--cta
    {
        margin:                 55px 0 0;
    }
    .gen__button--cta
    {
        @include inlineBlock;
    }
}