@charset "utf-8";
@import "../../node_modules/mixins-sass/src/mixins.scss";
@import "base.param.scss";
@import "sprite.scss";

// FULL ARTICLE
//=============
.item-full
{
    overflow:               hidden;
    padding:                0 0 30px;
    position:               relative;
    width:                  100%;
    .category
    {
        color:                  $blue-light-01;
        display:                block;
        font:                   normal 1.2em $simplo;
        letter-spacing:         1.5px;
        margin:                 0 0 15px;
        text-transform:         uppercase;
        z-index:                2;
    }
    .gen__description--big
    {
        color:                  $grey-04;
    }
    .preview
    {
        display:                block;
        float:                  left;
        margin:                 0 25px 25px 0;
    }
    .slideshow-context
    {
        height:                 430px;
        margin:                 60px auto;
        max-width:              820px;
        overflow:               hidden;
        position:               relative;
        width:                  65vw;
        .slide
        {
            &:before,&::before
            {
                display:                none;
            }
        }
        &:after,&::after
        {
            @include absoluteLeft(0,auto);
            background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6+ */
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0.65))); /* Chrome,Safari4+ */
            background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10+,Safari5.1+ */
            background: -o-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Opera 11.10+ */
            background: -ms-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* IE10+ */
            background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
            bottom:                 0;
            content:                " ";
            display:                block;
            height:                 60%;
            @include opacity(.6);
            pointer-events:         none;
            width:                  100%;

        }
    }
    .video-context,.poster-context
    {
        height:                 80vh;
        margin:                 60px 0;
        overflow:               hidden;
        position:               relative;
        width:                  100%;
        .embed
        {
            @include absoluteLeft(0,0);
            height:                 100%;
            @include opacity(0);
            @include transitions(opacity 300ms $easeInOutCubic);
            width:                  100%;
            z-index:                1;
        }
        .features
        {
            @include absoluteLeft(0,50%);
            @include translate(0,-50%);
            text-align:             center;
            width:                  100%;
            z-index:                2;
            .gen__button--play
            {
                .icon
                {
                    left:               5px;
                    top:                -1px;
                }
            }
            .subtitle
            {
                color:                  $white;
                display:                block;
                font:                   normal 1.2em $simplo;
                letter-spacing:         1.5px;
                margin:                 10px 0;
                text-transform:         uppercase;
                z-index:                2;
            }
        }
        .video
        {
            display:                block;
            margin:                 0;
            position:               relative;
            top:                    50%;
            @include translate(0,-50%);
        }
        &.is--playing
        {
            .embed
            {
                @include opacity(1);
            }
            .features,.video
            {
                @include opacity(0);
                pointer-events:         none;
            }
        }
    }
    .visual-full
    {
        display:                block;
        margin:                 50px auto 60px;
        max-width:              820px;
        overflow:               hidden;
        position:               relative;
        width:                  65vw;
    }
}

.item-full__context
{
    margin:                 0 auto;
    max-width:              700px;
    width:                  60vw;
}

.item-full__quote1
{
    margin:                 30px 0 10px;
    text-align:             center;
    .description
    {
        color:                  $blue-dark-01;
        display:                block;
        font:                   italic $openSemi 2.4em/1.2 $open;
        margin:                 0 0 35px;
    }
    &:before,&::before
    {
        color:                  $blue-light-01;
        content:                "“";
        font:                   normal 90px/1 "Gill sans";
    }
    &:after,&::after
    {
        color:                  $blue-light-01;
        content:                "”";
        font:                   normal 90px/1 "Gill sans";
    }
}
.item-full__quote2
{
    background:             #fafafa;
    margin:                 130px 0 40px;
    position:               relative;
    .description
    {
        color:                  $blue-dark-01;
        display:                block;
        font:                   $openSemi 2.4em/1.4 $open;
        margin:                 15px 0;
    }
    .wrapper
    {
        padding:                0 0 30px;
    }
    .portrait
    {
        display:                block;
        height:                 $full-portrait-size;
        margin:                 0 auto 20px;
        position:               relative;
        width:                  $full-portrait-size;
        margin-bottom:          -80px;
        position:               relative;
        top:                    -80px;
        .gen__bg
        {
            @include border-radius(155px);
            left:               50%;
            top:                50%;
            @include transform(1,0,-50%,-50%,0,0);
            @include transform-origin(0 0);
            @include transitions(transform 200ms $easeOutCubic);
            .safari &
            {
                @include transitions(-webkit-transform 200ms $easeOutCubic);
            }
        }
        .text
        {
            @include absoluteLeft(50%,50%);
            background:             rgba(255,255,255,.8);
            @include border-radius($full-portrait-size);
            display:                block;
            height:                 $full-portrait-size;
            @include opacity(0);
            @include transitions(opacity 500ms $easeOutCubic , transform 800ms $easeOutQuad);
            @include transform(.97,0,-50%,-50%,0,0);
            @include transform-origin(0 0);
            width:                  $full-portrait-size;
            z-index:                0;
            .safari &
            {
                @include transitions(opacity 500ms $easeOutCubic , -webkit-transform 800ms $easeOutQuad);
            }
            span
            {
                @include absoluteLeft(50%,50%);
                color:                  $blue-light-01;
                font-size:              35px;
                display:                block;
                @include transitions( transform 500ms $easeOutQuad);
                @include translate(-50%,-5%);
                .safari &
                {
                    @include transitions( -webkit-transform 500ms $easeOutQuad);
                }
            }
        }
        &:before,&::before
        {
            @include absoluteLeft(50%,50%);
            background: rgb(67,148,248); /* Old browsers */
            background: -moz-linear-gradient(left,  rgba(67,148,248,1) 0%, rgba(31,42,68,1) 100%); /* FF3.6+ */
            background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(67,148,248,1)), color-stop(100%,rgba(31,42,68,1))); /* Chrome,Safari4+ */
            background: -webkit-linear-gradient(left,  rgba(67,148,248,1) 0%,rgba(31,42,68,1) 100%); /* Chrome10+,Safari5.1+ */
            background: -o-linear-gradient(left,  rgba(67,148,248,1) 0%,rgba(31,42,68,1) 100%); /* Opera 11.10+ */
            background: -ms-linear-gradient(left,  rgba(67,148,248,1) 0%,rgba(31,42,68,1) 100%); /* IE10+ */
            background: linear-gradient(to right,  rgba(67,148,248,1) 0%,rgba(31,42,68,1) 100%); /* W3C */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4394f8', endColorstr='#1f2a44',GradientType=1 ); /* IE6-9 */
            @include border-radius($full-portrait-size);
            content:                " ";
            display:                block;
            height:                 $full-portrait-size + 7;
            @include translate(-50%,-50%);
            width:                  $full-portrait-size + 7;
            z-index:                0;
        }
        &:hover
        {
            .gen__bg
            {
                @include transform(.97,0,-50%,-50%,0,0);
            }
            .text
            {
                @include opacity(1);
                span
                {
                    @include translate(-50%,-50%);
                }
            }
        }
    }
    .title
    {
        color:                  $grey-01;
        display:                block;
        font:                   normal 1.4em $simplo;
        letter-spacing:         .25px;
        margin:                 0 0 15px;
        text-transform:         uppercase;
    }
}

.item-full__title
{
    color:                  $blue-dark-01;
    font-size:              4.6em;
}
.item-full__subtitle
{
    color:                  $grey-01;
    font-size:              2.4em;
    margin:                 10px 0 50px;
}

.item-full__share
{
    display:                block;
    float:                  left;
    margin:                 0 25px 25px 0;
    overflow:               hidden;
    position:               relative;
    text-align:             center;
    width:                  334px;
    .button-action
    {
        color:                  $blue-dark-01;
        display:                block;
        font:                   normal 1.2em $simplo;
        letter-spacing:         1.5px;
        margin:                 10px 0 0;
        text-transform:         uppercase;
        @include transitions(color 200ms $easeOutCubic);
        img
        {
            height:                 24px;
            @include opacity(.3);
            position:               relative;
            top:                    5px;
            @include transitions(opacity 300ms $easeOutCubic);
        }
        &:hover
        {
            color:                  $blue-light-01;
            img
            {
                @include opacity(1);
            }
        }
    }
    .info
    {
        background:             $white;
        @include box-sizing(border-box);
        height:                 85px;
        padding:                10px 10px;
        text-align:             left;
        .content
        {
            position:               relative;
            top:                    50%;
            @include translate(0,-50%);
        }
        .gen__subtitle--big
        {
            color:                  $blue-dark-01;
        }
        .subtitle
        {
            color:                  $grey-01;
        }
    }
    .nested
    {
        @include absoluteLeft(0,auto);
        bottom:                 0;
        padding:                0 0 20px;
        @include opacity(0);
        @include translate(0,-5px);
        @include transitions(all 600ms $easeOutCubic);
        width:                  100%;
        .buttons
        {
            display:                block;
            margin:                 10px 0 0;
            overflow:               hidden;
            text-align:             center;
            .icon
            {
                border:                 2px solid $white;
                @include border-radius(30px);
                color:                  $white;
                height:                 30px;
                @include inlineBlock;
                font-size:              30px;
                margin:                 0 10px;
                padding:                5px;
                position:               relative;
                @include transitions(border-color 300ms $easeOutCubic);
                width:                  30px;
                z-index:                1;
                &.icon-social-linkedin
                {
                    &:before,&::before
                    {
                        left:               1px;
                        position:           relative;
                        top:                -1px;
                    }
                }
                &:after,&::after
                {
                    @include absoluteLeft(50%,50%);
                    background:             $blue-light-01;
                    @include border-radius(30px + 5);
                    content:                " ";
                    display:                block;
                    height:                 105%;
                    @include opacity(0);
                    @include translate(-50%,-50%);
                    @include transitions(all 300ms $easeOutCubic);
                    @include transition-delay(100ms);
                    width:                  105%;
                    z-index:                -1;
                }
                &:hover
                {
                    border-color:               $blue-light-01;
                    &:after,&::after
                    {
                        @include opacity(1);
                    }
                }
            }
        }
        .text
        {
            color:                  $white;
            font:                   normal 1.4em $simplo;
            letter-spacing:         1.5px;
            text-transform:         uppercase;
        }
        &:after,&::after
        {
            @include absoluteLeft(0,auto);
            background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6+ */
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0.65))); /* Chrome,Safari4+ */
            background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10+,Safari5.1+ */
            background: -o-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Opera 11.10+ */
            background: -ms-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* IE10+ */
            background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
            bottom:                 0;
            content:                " ";
            display:                block;
            height:                 100%;
            @include opacity(0);
            pointer-events:         none;
            @include transitions(all 300ms $easeOutCubic);
            width:                  100%;
        }

    }
    .visual-context
    {
        display:                block;
        position:               relative;
        .visual
        {
            display:                block;
            width:                  100%;
        }
        &:after,&::after
        {
            @include absoluteLeft(0,auto);
            background: rgb(67,148,248); /* Old browsers */
            background: -moz-linear-gradient(left,  rgba(67,148,248,1) 0%, rgba(31,42,68,1) 100%); /* FF3.6+ */
            background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(67,148,248,1)), color-stop(100%,rgba(31,42,68,1))); /* Chrome,Safari4+ */
            background: -webkit-linear-gradient(left,  rgba(67,148,248,1) 0%,rgba(31,42,68,1) 100%); /* Chrome10+,Safari5.1+ */
            background: -o-linear-gradient(left,  rgba(67,148,248,1) 0%,rgba(31,42,68,1) 100%); /* Opera 11.10+ */
            background: -ms-linear-gradient(left,  rgba(67,148,248,1) 0%,rgba(31,42,68,1) 100%); /* IE10+ */
            background: linear-gradient(to right,  rgba(67,148,248,1) 0%,rgba(31,42,68,1) 100%); /* W3C */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4394f8', endColorstr='#1f2a44',GradientType=1 ); /* IE6-9 */
            bottom:                 0;
            content:                " ";
            display:                block;
            height:                 3px;
            width:                  100%;

        }
        &:hover
        {
            .nested
            {
                @include opacity(1);
                @include translate(0,0);
                &:after,&::after
                {
                    @include opacity(.6);
                }
            }
        }
    }
    &.with--zoom
    {
        .visual-context
        {
            overflow:           hidden;
        }
        img
        {
            display:                block;
            @include scale(1.05);
            @include transitions(all 300ms $easeOutQuad);
        }
        &:hover
        {
            img
            {
                @include scale(1);
            }
        }
    }

}