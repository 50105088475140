@charset "utf-8";
@import "../../node_modules/mixins-sass/src/mixins.scss";
@import "base.param.scss";
@import "sprite.scss";

body {
    background:             $white;
}

// Master
//=======
#master
{
    height:                 auto;
    min-height:             100%;
    overflow:               hidden;
    position:               relative;
    width:                  100%;
}


// Header
//=======
#main-banner
{
    @include absoluteLeft(30px,30px);
    height:                 $header-height;
    position:               fixed;
    right:                  0;
    z-index:                $global-zindex;
    .logo
    {
        display:                block;
        height:                 100%;
        width:                  $logo-width;
    }
    .logo__visual
    {
        @include absoluteLeft(30px,30px);
        height:                 $header-height;
        position:               fixed;
        width:                  $logo-width;
        svg
        {
            display:                block;
            height:                 100%;
            width:                  100%;
            path
            {
                fill:                   $white;
                @include transitions(all 500ms $easeOutCubic);
                &:nth-child(1),&:nth-child(3),&:nth-child(4)
                {
                    .skin--isdark &
                    {
                        fill:                   $blue-dark-01;
                    }
                }
                &:nth-child(2),&:nth-child(5)
                {
                    .skin--isdark &
                    {
                        fill:                   $blue-light-02;
                    }
                }
            }
        }
    }
    .menu
    {
        @include absoluteRight(0,30px);
        height:                 $header-height;
        position:               fixed;
        a
        {
            display:            block;
            float:              left;
        }
    }
    .gen__button--apply
    {
        line-height:            $header-height;
    }
    .is--lightened &
    {
        .logo__visual
        {
            svg
            {
                path
                {
                    &:nth-child(1),&:nth-child(2),&:nth-child(3)
                    {
                        @include opacity(0);
                    }
                }
            }
        }
    }
    .has--sticky &,.mainnav--isOpened &
    {
         svg
        {
            path
            {
                fill:                   $white !important;
            }
        }
    }
}


// Main content
//=============
#main-content {
    display:                block;
    height:                 auto;
    margin:                 0 0 0;
    position:               relative;
    width:                  100%;
    z-index:                0;
}


// Footer
//=======
#main-footer
{
    margin:                 0 0 0;
}
.footer__features
{
    display:                block;
    overflow:               hidden;
    position:               relative;
    .gen__bg
    {
        position:               relative;
    }
    .misc
    {
        .button
        {
            span
            {
                @include inlineBlock;
                position:           relative;
                &:after,&::after
                {
                    @include absoluteLeft(0,auto);
                    background:             $white;
                    bottom:                 -3px;
                    content:                " ";
                    display:                block;
                    height:                 0;
                    @include transitions(height 100ms ease-out);
                    width:                  100%;
                }
            }
            &:hover
            {
                span
                {
                    &:after,&::after
                    {
                        height:                 3px;
                    }
                }
            }
            &:after
            {
                background:             $white;
                content:                " ";
                @include inlineBlock;
                height:                 17px;
                margin:                 0 10px;
                position:               relative;
                top:                    4px;
                width:                  2px;
            }
            &:last-child
            {
                &:after
                {
                    display:                none;
                }
            }
        }
    }
    .social
    {
        .icon
        {
            border:                 2px solid $white;
            @include border-radius(44px);
            color:                  $white;;
            font-size:              25px;
            height:                 44px;
            @include inlineBlock;
            line-height:            44px;
            margin:                 0 5px;
            position:               relative;
            @include transitions(color 500ms $easeOutCubic);
            width:                  44px;
            &:before
            {
                position:               relative;
                z-index:                1;
            }
            &.icon-play
            {
                &:before
                {
                    left:                   3px;
                }
            }
            &:after
            {
                @include absoluteLeft(50%,50%);
                background:             $blue-dark-01;
                @include border-radius(44px);
                content:                " ";
                display:                block;
                height:                 44px;
                @include transform(0,0,-50%,-50%,0,0);
                @include transitions(all 400ms $easeInOutQuart);
                @include transform-origin(0 0 );
                width:                  44px;
                z-index:                0;
            }
            &:hover
            {
                color:                      $blue-light-01;
                &:after
                {
                    @include transform(1,0,-50%,-50%,0,0);
                }
            }
        }
    }
    .visual
    {
        display:                block;
        visibility:             hidden;
    }
    .wrapper
    {
        @include absoluteLeft(0,0);
        height:                 100%;
        width:                  100%;
        .dp-t
        {
            height:                 100%;
            width:                  100%;
        }
        .column
        {
            @include box-sizing(border-box);
            display:                table-cell;
            height:                 100%;
            padding:                0 30px;
            vertical-align:         middle;
            width:                  33.33%;
        }
    }
}

.footer__partners
{
    padding:                25px 0;
    text-align:             center;
    width:                  100%;
    .title
    {
        color:                  $blue-dark-01;
        font:                   normal 1.4em $simplo;
        letter-spacing:         1.5px;
        text-transform:         uppercase;
    }
    .list
    {
        list-style:             none;
        margin:                 40px 0 0;
        li
        {
            @include inlineBlock;
            height:             50px;
            @include opacity(1);
            margin:             0 1.5vw;
            a
            {
                display:            block;
                height:             100%;
            }
            .partner
            {
                position:               relative;
                &.logo-h1
                {
                    top:                    5px;
                }
                &.logo-tns
                {
                    top:                    -5px;
                }
                &.logo-fab
                {
                    top:                    2px;
                }
                &:before
                {
                    @include transitions(opacity 500ms $easeOutCubic);
                }
            }
            &:hover
            {
                .partner
                {
                    &:before
                    {
                        @include opacity(1);
                    }
                }
            }
            &.active
            {
                .partner
                {
                    &:before
                    {
                        @include opacity(1);
                    }
                }
            }
        }
    }
}