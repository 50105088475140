@charset "utf-8";
@import "../../node_modules/mixins-sass/src/mixins.scss";
@import "base.param.scss";
@import "sprite.scss";

// MAIN NAVIGATION
//================

// trigger
.trigger-mainnav
{
    background:             $blue-dark-01;
    display:                block;
    height:                 100%;
    position:               relative;
    text-indent:            -999em;
    @include transitions(background 300ms $easeOutCubic);
    width:                  65px;
    .icon
    {
        height:                 17px;
        left:                   50%;
        margin:                 -8.5px 0 0 -18.5px;
        position:               relative;
        top:                    50%;
        width:                  37px;
    }
    .icon-close
    {
        @include absoluteLeft(50%,50%);
        color:                  $white;
        font-size:              30px;
        @include opacity(0);
        text-indent:            0;
        @include transform(1,0,-50%,-50%,0,0);
        @include transform-origin(0 0);
        @include transitions(opacity 300ms $easeOutCubic , transform 300ms $easeOutQuad);
        .safari &
        {
            @include transitions(opacity 300ms $easeOutCubic , -webkit-transform 300ms $easeOutQuad);
        }
    }
    .bar
    {
        border-top:             3px solid $white;
        display:                block;
        margin:                 0 0 4px;
        position:               relative;
        @include scaleXY(1,1);
        @include transitions(all 300ms $easeInOutBack);
        width:                  100%;
        &:nth-child(2)
        {
            @include transition-delay(50ms);
        }
    }
    &:hover
    {
        background:             $blue-light-01;
        .bar
        {
            &:nth-child(1),&:nth-child(3)
            {
                @include scaleXY(.8,1);
            }
            &:nth-child(2)
            {
                @include scaleXY(.6,1);
            }
        }
        .icon-close
        {
            color:              $blue-dark-01;
        }
    }
    &.active
    {
        .icon-close
        {
            @include opacity(2);
            @include transform(1,0,-50%,-50%,0,0);
        }
        .bar
        {
            @include opacity(0);
        }
        &:hover
        {
            .icon-close
            {
                @include transform(1.2,0,-50%,-50%,0,0);
            }

        }
    }
}

// content
#main-nav
{
    @include absoluteLeft(0,0);
    background:             $blue-light-01;
    height:                 100%;
    @include opacity(0);
    position:               fixed;
    pointer-events:         none;
    @include transitions(all 800ms $easeInOutBack);
    width:                  100%;
    z-index:                $global-zindex - 1;
    .ie9 &,.ie10 &
    {
        display:                none;
    }
    .footer__features
    {
        @include absoluteLeft(0,auto);
        bottom:                 0;
        height:                 100px;
        @include opacity(0);
        @include transitions(all 500ms $easeOutCubic);
        @include transition-delay(1000ms);
        width:                  100%;
    }
    &.active
    {
        @include opacity(1);
        pointer-events:         auto;
        .footer__features
        {
            @include opacity(1);
        }
        .ie9 &,.ie10 &
        {
            display:                block;
        }
    }
}
.main-nav__menu
{
    @include absoluteLeft(50%,50%);
    list-style:             none;
    margin:                 -125px 0 0 -225px;
    text-align:             center;
    width:                  450px;
    > li
    {
        color:                  #b2cefc;
        font:                   normal 2.4em $simplo;
        letter-spacing:         .5px;
        margin:                 0 0 15px;
        overflow:               hidden;
        text-transform:         uppercase;
        a
        {
            color:                  inherit;
            @include transitions(color 300ms $easeOutCubic);
        }
        &.active,&:hover
        {
            color:                  $white;
            .sub-menu
            {
                ul
                {
                    &:before,&::before,&:after,&::after
                    {
                        @include scaleXY(1,1);
                    }
                }
            }
        }
    }
    .sub-menu
    {
        height:                 0;
        list-style:             none;
        overflow:               hidden;
        @include transitions(height 800ms $easeInOutQuart);
        ul
        {
            list-style:             none;
            margin:                 10px 0;
            padding:                15px 0;
            position:               relative;
            &:before,&::before
            {
                @include absoluteLeft(0,0);
                background:             $white;
                content:                " ";
                display:                block;
                height:                 3px;
                @include scaleXY(0,1);
                @include transitions(all 300ms $easeOutCubic);
                @include transition-delay(300ms);
                width:                  100%;
            }
            &:after,&::after
            {
                @include absoluteLeft(0,auto);
                background:             $white;
                bottom:                 0;
                content:                " ";
                display:                block;
                height:                 3px;
                @include scaleXY(0,1);
                @include transitions(all 300ms $easeOutCubic);
                @include transition-delay(300ms);
                width:                  100%;
            }
        }
        li
        {
            color:                  $white;
            font:                   $openLight .65em $open;
            text-transform:         none;
            &:hover
            {
                font-weight:            $openSemi;
            }
        }
    }
}