/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;
*/
$partner_access-bw-x: 637px;
$partner_access-bw-y: 0px;
$partner_access-bw-offset-x: -637px;
$partner_access-bw-offset-y: 0px;
$partner_access-bw-width: 111px;
$partner_access-bw-height: 28px;
$partner_access-bw-total-width: 1230px;
$partner_access-bw-total-height: 51px;
$partner_access-bw-image: '../img/common/sprite.png';
$partner_access-bw: 637px 0px -637px 0px 111px 28px 1230px 51px '../img/common/sprite.png';
$partner_access-color-x: 526px;
$partner_access-color-y: 0px;
$partner_access-color-offset-x: -526px;
$partner_access-color-offset-y: 0px;
$partner_access-color-width: 111px;
$partner_access-color-height: 28px;
$partner_access-color-total-width: 1230px;
$partner_access-color-total-height: 51px;
$partner_access-color-image: '../img/common/sprite.png';
$partner_access-color: 526px 0px -526px 0px 111px 28px 1230px 51px '../img/common/sprite.png';
$partner_fab-bw-x: 0px;
$partner_fab-bw-y: 0px;
$partner_fab-bw-offset-x: 0px;
$partner_fab-bw-offset-y: 0px;
$partner_fab-bw-width: 73px;
$partner_fab-bw-height: 27px;
$partner_fab-bw-total-width: 1230px;
$partner_fab-bw-total-height: 51px;
$partner_fab-bw-image: '../img/common/sprite.png';
$partner_fab-bw: 0px 0px 0px 0px 73px 27px 1230px 51px '../img/common/sprite.png';
$partner_fab-color-x: 73px;
$partner_fab-color-y: 0px;
$partner_fab-color-offset-x: -73px;
$partner_fab-color-offset-y: 0px;
$partner_fab-color-width: 73px;
$partner_fab-color-height: 27px;
$partner_fab-color-total-width: 1230px;
$partner_fab-color-total-height: 51px;
$partner_fab-color-image: '../img/common/sprite.png';
$partner_fab-color: 73px 0px -73px 0px 73px 27px 1230px 51px '../img/common/sprite.png';
$partner_h1-bw-x: 1105px;
$partner_h1-bw-y: 0px;
$partner_h1-bw-offset-x: -1105px;
$partner_h1-bw-offset-y: 0px;
$partner_h1-bw-width: 125px;
$partner_h1-bw-height: 26px;
$partner_h1-bw-total-width: 1230px;
$partner_h1-bw-total-height: 51px;
$partner_h1-bw-image: '../img/common/sprite.png';
$partner_h1-bw: 1105px 0px -1105px 0px 125px 26px 1230px 51px '../img/common/sprite.png';
$partner_h1-color-x: 980px;
$partner_h1-color-y: 0px;
$partner_h1-color-offset-x: -980px;
$partner_h1-color-offset-y: 0px;
$partner_h1-color-width: 125px;
$partner_h1-color-height: 26px;
$partner_h1-color-total-width: 1230px;
$partner_h1-color-total-height: 51px;
$partner_h1-color-image: '../img/common/sprite.png';
$partner_h1-color: 980px 0px -980px 0px 125px 26px 1230px 51px '../img/common/sprite.png';
$partner_lime-bw-x: 864px;
$partner_lime-bw-y: 0px;
$partner_lime-bw-offset-x: -864px;
$partner_lime-bw-offset-y: 0px;
$partner_lime-bw-width: 116px;
$partner_lime-bw-height: 30px;
$partner_lime-bw-total-width: 1230px;
$partner_lime-bw-total-height: 51px;
$partner_lime-bw-image: '../img/common/sprite.png';
$partner_lime-bw: 864px 0px -864px 0px 116px 30px 1230px 51px '../img/common/sprite.png';
$partner_lime-color-x: 748px;
$partner_lime-color-y: 0px;
$partner_lime-color-offset-x: -748px;
$partner_lime-color-offset-y: 0px;
$partner_lime-color-width: 116px;
$partner_lime-color-height: 30px;
$partner_lime-color-total-width: 1230px;
$partner_lime-color-total-height: 51px;
$partner_lime-color-image: '../img/common/sprite.png';
$partner_lime-color: 748px 0px -748px 0px 116px 30px 1230px 51px '../img/common/sprite.png';
$partner_pch-bw-x: 229px;
$partner_pch-bw-y: 0px;
$partner_pch-bw-offset-x: -229px;
$partner_pch-bw-offset-y: 0px;
$partner_pch-bw-width: 83px;
$partner_pch-bw-height: 41px;
$partner_pch-bw-total-width: 1230px;
$partner_pch-bw-total-height: 51px;
$partner_pch-bw-image: '../img/common/sprite.png';
$partner_pch-bw: 229px 0px -229px 0px 83px 41px 1230px 51px '../img/common/sprite.png';
$partner_pch-color-x: 146px;
$partner_pch-color-y: 0px;
$partner_pch-color-offset-x: -146px;
$partner_pch-color-offset-y: 0px;
$partner_pch-color-width: 83px;
$partner_pch-color-height: 41px;
$partner_pch-color-total-width: 1230px;
$partner_pch-color-total-height: 51px;
$partner_pch-color-image: '../img/common/sprite.png';
$partner_pch-color: 146px 0px -146px 0px 83px 41px 1230px 51px '../img/common/sprite.png';
$partner_tns-bw-x: 419px;
$partner_tns-bw-y: 0px;
$partner_tns-bw-offset-x: -419px;
$partner_tns-bw-offset-y: 0px;
$partner_tns-bw-width: 107px;
$partner_tns-bw-height: 51px;
$partner_tns-bw-total-width: 1230px;
$partner_tns-bw-total-height: 51px;
$partner_tns-bw-image: '../img/common/sprite.png';
$partner_tns-bw: 419px 0px -419px 0px 107px 51px 1230px 51px '../img/common/sprite.png';
$partner_tns-color-x: 312px;
$partner_tns-color-y: 0px;
$partner_tns-color-offset-x: -312px;
$partner_tns-color-offset-y: 0px;
$partner_tns-color-width: 107px;
$partner_tns-color-height: 51px;
$partner_tns-color-total-width: 1230px;
$partner_tns-color-total-height: 51px;
$partner_tns-color-image: '../img/common/sprite.png';
$partner_tns-color: 312px 0px -312px 0px 107px 51px 1230px 51px '../img/common/sprite.png';

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}
*/
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}
