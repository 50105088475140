@charset "utf-8";
@import "../../node_modules/mixins-sass/src/mixins.scss";
@import "base.param.scss";
@import "sprite.scss";

// FILTER
//=======
.filter-blog
{
    @include absoluteLeft(0,0);
    height:                 $blog-filter-height;
    @include calc(top , "100vh - 105px");
    overflow:               hidden;
    @include transitions(height 500ms $easeOutCubic);
    width:                  100%;
    z-index:                5;
    .itw-related &
    {
        top:                    0;
    }
    .button
    {
        background:             $blue-light-01;
        color:                  $white;
        font:                   normal 1.4em $simplo;
        @include inlineBlock;
        height:                 $blog-filter-height;
        letter-spacing:         1.5px;
        line-height:            $blog-filter-height;
        position:               relative;
        text-align:             center;
        text-transform:         uppercase;
        width:                  100%;
        z-index:                2;
        .icon
        {
            display:                none;
            font-size:              25px;
            position:               relative;
            top:                    6px;
            &.icon-grid
            {
                @include inlineBlock;
            }
        }
    }
    .collection
    {
        background:             $white;
        overflow:               hidden;
        padding:                60px 0 30px;
        position:               relative;
        z-index:                2;
        .item
        {
            float:                  left;
            margin:                 0 0 20px 0;
            @include transitions(opacity 500ms $easeOutCubic);
            width:                  20%;
            .visual
            {
                @include box-sizing(border-box);
                display:                block;
                padding:                0 5px;
                width:                  100%;
            }
            .title
            {
                color:                  $blue-dark-01;
                font:                   normal 1.4em $simplo;
                letter-spacing:         1.5px;
                margin:                 15px 0 0;
                text-align:             center;
                text-transform:         uppercase;
            }
            &.is--disabled
            {
                @include opacity(.5);
            }
        }
    }
    &.is--active
    {
        .icon
        {
            &.icon-grid
            {
                display:                none;
            }
            &.icon-close
            {
                @include inlineBlock;
            }
        }
    }
    &.is--sticky
    {
        position:               fixed;
        top:                    0;
        @include doAnim(sticky,800ms,$easeOutExpo,0s,1,forwards);
    }
}

// ITW
//====
.itw-related
{
    margin:                 70px 0;
    padding:                $blog-filter-height - 1 0 0;
    position:               relative;
}
.grid-itw
{
    margin:                 50px auto 70px;
    max-width:              1070px;
    position:               relative;
    width:                  70vw;
}
.grid-itw__cell,.grid-itw__sizer
{
    padding-bottom:         33.33%;
    @include perspective(1000px);
    position:               absolute;
    width:                  33.33%;
    &.x0
    {
        left:                   0;
    }
    &.x1
    {
        left:                   33.33%;
    }
    &.x2
    {
        left:                   66.66%;
    }
    &.y0
    {
        top:                    0;
    }
    &.y1
    {
        top:                    33.33%;
    }
    &.y2
    {
        top:                    66.67%;
    }
    &.w2
    {

        padding-bottom:         16.665%;
        width:                  66.66%;
    }
    &.yHalf
    {
        margin:                 16.665% 0 0;
    }
}
.grid-itw__sizer
{
    position:               relative;
}

.itw-item
{
    @include absoluteLeft(0,0);
    @include box-sizing(border-box);
    cursor:                 pointer;
    display:                block;
    height:                 100%;
    overflow:               hidden;
    padding:                5px;
    text-align:             center;
    @include transitions(all 1s $easeOutExpo);
    -webkit-transform:      rotateY(0deg) scale(1);
      -ms-transform:        rotateY(0deg) scale(1); // IE9+
          transform:        rotateY(0deg) scale(1);
    width:                  100%;
    .dp-t
    {
        height:                 100%;
        @include transitions(background 300ms $easeOutExpo);
        width:                  100%;
    }
    &.is--answer
    {
        @include absoluteLeft(0,0);
        @include box-sizing(border-box);
        height:                 33.33%;
        @include opacity(0);
        pointer-events:         none;
        @include transitions(opacity 300ms $easeOutCubic);
        width:                  33.33%;
        .ie9 &,.ie10 &
        {
            display:                none;
        }
        .answer
        {
            color:                  $white;
            font:                   $openLight 1.8em/1.3 $open;
        }
        .dp-t
        {
            @include box-sizing(border-box);
            background:         $blue-light-01 !important;
            padding:            20px;
            text-align:         left;
        }
        .icon-close
        {
            @include absoluteRight(10px,10px);
            color:              $white;
            font-size:          20px;
        }
        .question
        {
            color:                  $white;
            font:                   $openSemi 2.4em $open;
        }
        &.active
        {
            @include opacity(1);
            pointer-events:         auto;
            .ie9 &,.ie10 &
            {
                display:                block;
            }
        }
    }
    &.is--question1
    {
        .dp-t
        {
            background:             $grey-03;
        }
    }
    &.is--question2
    {
        .dp-t
        {
            background:             #e6e6e6;
        }
        .text
        {
            color:                  $blue-light-01;
            font:                   $openLight 2.6em/1.2 $open;
        }
    }
    &.is--visual
    {
        .nested
        {
            background: rgb(67,148,248); /* Old browsers */
            background: -moz-linear-gradient(left,  rgba(67,148,248,1) 0%, rgba(31,42,68,1) 100%); /* FF3.6+ */
            background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(67,148,248,1)), color-stop(100%,rgba(31,42,68,1))); /* Chrome,Safari4+ */
            background: -webkit-linear-gradient(left,  rgba(67,148,248,1) 0%,rgba(31,42,68,1) 100%); /* Chrome10+,Safari5.1+ */
            background: -o-linear-gradient(left,  rgba(67,148,248,1) 0%,rgba(31,42,68,1) 100%); /* Opera 11.10+ */
            background: -ms-linear-gradient(left,  rgba(67,148,248,1) 0%,rgba(31,42,68,1) 100%); /* IE10+ */
            background: linear-gradient(to right,  rgba(67,148,248,1) 0%,rgba(31,42,68,1) 100%); /* W3C */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4394f8', endColorstr='#1f2a44',GradientType=1 ); /* IE6-9 */
            @include box-sizing(border-box);
            display:                block;
            height:                 99.9%;
            padding:                 3px;
            width:                  auto;
        }
        .visual
        {
            display:                block;
            height:                 100%;
            width:                  100%;
        }

    }
    &.is--active
    {
        -webkit-transform:      rotateY(100deg) scale(.7);
          -ms-transform:        rotateY(100deg) scale(.7); // IE9+
              transform:        rotateY(100deg) scale(.7);
    }
    .itw-item__text1
    {
        color:                  $blue-light-01;
        font:                   $openSemi 3.6em/1.2 $open;
    }
    .itw-item__text2
    {
        color:                  $blue-light-01;
        font:                   $openLight 2.6em/1.2 $open;
    }
    .itw-item__text3
    {
        color:                  $blue-light-01;
        font:                   $openSemi 2.6em/1.2 $open;
    }
    .itw-item__text4
    {
        color:                  $blue-light-01;
        font:                   $openSemi 3em/1.2 $open;
    }
    &:hover
    {
        .dp-t
        {
            background:             #d7d7d7;
        }
    }
}

