@charset "utf-8";
@import "../../node_modules/mixins-sass/src/mixins.scss";
@import "base.param.scss";
@import "sprite.scss";

// HARDWARE GRID
//==============
.hardware-grid
{
    @include box-sizing(border-box);
    margin:                 0 auto 100px;
    overflow:               hidden;
    padding:                0 $hardware-grid-gutter*3 0 0;
    width:                  100%;
    &.is--big
    {
        margin-bottom:          0;
        padding:                0 $hardware-grid-gutter 0 0;
    }
    &.has--nomargin
    {
        margin:                 0 auto;
    }
}
.hardware-grid__item
{
    @include box-sizing(border-box);
    display:                block;
    float:                  left;
    margin:                 0 0 10px;
    overflow:               hidden;
    padding:                0;
    position:               relative;
    width:                  25%;
    .button
    {
        color:                  $white;
        font-size:              45px;
        line-height:            .7;
    }
    .category
    {
        @include absoluteLeft(15px,20px);
        background:             $blue-light-01;
        color:                  $white;
        display:                block;
        font:                   normal 1.2em $simplo;
        letter-spacing:         1.5px;
        padding:                18px 20px;
        text-transform:         uppercase;
        z-index:                2;
    }
    .context
    {
        display:                block;
        overflow:               hidden;
    }
    .features
    {
        @include absoluteLeft(15px,auto);
        bottom:                 15px;
        width:                  50%;
        z-index:                2;
    }
    .title
    {
        font-size:              2.4em;
        @include translate(0,0);
        @include transitions(all 400ms $easeOutExpo);
    }
    .visual
    {
        display:                block;
        @include scale(1.05);
        @include transitions(all 500ms $easeOutQuad);
        width:                  100%;
    }
    &:after,&::after
    {
        @include absoluteLeft(0,auto);
        background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0.65))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* IE10+ */
        background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
        bottom:                 0;
        content:                " ";
        display:                block;
        height:                 80%;
        @include opacity(1);
        pointer-events:         none;
        @include transitions(opacity 1000ms $easeOutCubic);
        width:                  100%;
        z-index:                1;
        .ie9 &
        {
            background:             $black;
            height:                 100%;
            @include opacity(.2);
        }
    }
    &:nth-child(4n+2)
    {
        right:              -10px;
    }
    &:nth-child(4n+3)
    {
        right:              -20px;
    }
    &:nth-child(4n+4)
    {
        right:              -30px;
    }
    .is--big &
    {
        width:                  50%;
        &:nth-child(4n+2)
        {
            right:              -10px;
        }
        &:nth-child(4n+3)
        {
            right:              -20px;
        }
    }
    &:hover
    {
        .button
        {
            @include doAnim(moveArrow,800ms,$easeOutExpo,0s,1,forwards);
        }
        .title
        {
            @include translate(10px,0);
        }
        .visual
        {
            @include scale(1);
        }
        &:after,&::after
        {
            @include opacity(.4);
            .ie9 &
            {
                @include opacity(.2);
            }
        }
    }
}