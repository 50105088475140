@charset "utf-8";
@import "../../node_modules/mixins-sass/src/mixins.scss";
@import "base.param.scss";
@import "sprite.scss";

// GRID
//=====
.workspace-grid
{
    background:             $blue-dark-01;
    width:                  100%;
}

.workspace-grid__sizer
{
    width:                  10%;
}

.workspace-grid__item
{
    overflow:               hidden;
    padding-bottom:         10%;
    width:                  10%;
    &.for--social
    {
        background:             $blue-light-01;
        @include transitions(background 400ms $easeOutCubic);
        .dp-t
        {
            height:                 100%;
        }
        .title
        {
            color:                  $white;
            font:                   $openLight 1.4em $open;
            display:                table-cell;
            padding:                0 10px;
            text-align:             center;
            vertical-align:         middle;
            strong
            {
                font-weight:            $openSemi;
            }
        }
        &:hover
        {
            background:             $blue-dark-01;
        }
    }
    .context
    {
        @include absoluteLeft(0,0);
        height:                 100%;
        width:                  100%;
    }
    .gen__centerContext
    {
        @include perspective(1000px);
    }
    .features
    {
        @include absoluteLeft(0,0);
        display:                table;
        height:                 100%;
        text-align:             center;
        width:                  100%;
        &:before
        {
            @include absoluteLeft(0,0);
            background:             $blue-dark-01;
            content:                " ";
            display:                block;
            height:                 100%;
            @include opacity(0);
            pointer-events:         none;
            @include transitions(opacity 500ms $easeOutCubic);
            width:                  100%;
            z-index:                0;
        }
        .title
        {
            color:                  $white;
            font:                   normal 1.4em $simplo;
            letter-spacing:         .25px;
            @include opacity(0);
            text-transform:         uppercase;
            @include translate3d(0,0,50px);
            @include transitions(opacity 500ms $easeOutCubic , transform 800ms $easeOutQuad);
        }
        .subtitle
        {
            color:                  $white;
            font:                   normal 1.2em $simplo;
            margin:                 3px 0 5px;
            @include opacity(0);
            text-transform:         uppercase;
            @include translate3d(0,0,50px);
            @include transitions(opacity 500ms $easeOutCubic , transform 800ms $easeOutQuad);
            @include transition-delay(50ms);
        }
        .icon
        {
            color:                  $white;
            font-size:              50px;
            line-height:            .5;
            @include opacity(0);
            @include translate(-10%,0);
            @include transitions(opacity 500ms $easeOutCubic , transform 800ms $easeOutExpo);
            @include transition-delay(100ms);
        }
    }
    .fill
    {
        @include absoluteLeft(0,0);
        background:             $blue-dark-01;
        height:                 100%;
        @include opacity(0);
        @include transitions(opacity 500ms $easeOutCubic );
        width:                  100%;
    }
    .type
    {
        @include absoluteLeft(50%,50%);
        color:                  $white;
        display:                block;
        font-size:              25px;
        margin:                 -12.5px 0 0 -12.5px;
        @include opacity(0);
        @include translate(0,-10px);
        @include transitions(opacity 500ms $easeOutCubic , transform 800ms $easeOutExpo);
        z-index:                1;
    }
    .visual
    {
        display:                block;
        height:                 100%;
        width:                  100%;
    }
    &.is--big
    {
        padding-bottom:         20%;
        width:                  20%;
    }
    &.is--horizontal
    {
        width:                  20%;
    }
    &.is--vertical
    {
        padding-bottom:         20%;
    }
    &:hover
    {
        .features
        {
            .title,.subtitle
            {
                @include opacity(1);
                @include translate3d(0,0,0);
            }
            .icon
            {
                @include opacity(1);
                @include translate(0,0);
            }
            &:before
            {
                @include opacity(.5);
            }
        }
        .fill
        {
            @include opacity(.5);
        }
        .type
        {
            @include opacity(1);
            @include translate(0,0);

        }
    }
}