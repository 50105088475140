@charset "utf-8";
@import "../../node_modules/mixins-sass/src/mixins.scss";
@import "base.param.scss";
@import "sprite.scss";

// COMMUNITY
//==========
.community
{
    padding:                0 0 60px;
    position:               relative;
    text-align:             center;
    width:                  100%;
}
.community-collection
{
    @include box-sizing(border-box);
    margin:                 0 auto;
    overflow:               hidden;
    padding:                20px $community-grid-gutter*3 0 0;
    width:                  100%;
}
.community-collection__item
{
    @include box-sizing(border-box);
    display:                block;
    float:                  left;
    margin:                 0 0 $community-grid-gutter;
    padding:                0;
    position:               relative;
    text-align:             center;
    width:                  25%;
    .subtitle
    {
        color:                  $grey-01;
        font:                   normal 1.4em $simplo;
        letter-spacing:         .25px;
        margin:                 3px 0 0;
        text-transform:         uppercase;
    }
    .title
    {
        color:                  $blue-dark-01;
        font:                   normal 1.4em $simplo;
        letter-spacing:         .25px;
        text-transform:         uppercase;
    }
    .visual-context
    {
        display:                block;
        height:                 $community-grid-size;
        margin:                 0 auto 20px;
        position:               relative;
        width:                  $community-grid-size;
        .gen__bg
        {
            @include backface-visibility(hidden);
            @include border-radius(155px);
            left:               50%;
            top:                50%;
            @include transform(1,0,-50%,-50%,0,0);
            @include transform-origin(0 0);
            @include transitions(transform 200ms $easeOutCubic);
            .safari &
            {
                @include transitions(-webkit-transform 200ms $easeOutCubic);
            }
        }
        .text
        {
            @include absoluteLeft(50%,50%);
            background:             rgba(255,255,255,.8);
            @include border-radius($community-grid-size);
            display:                block;
            height:                 $community-grid-size;
            @include opacity(0);
            @include transitions(opacity 500ms $easeOutCubic , transform 800ms $easeOutQuad);
            @include transform(.97,0,-50%,-50%,0,0);
            @include transform-origin(0 0);
            width:                  $community-grid-size;
            z-index:                0;
            .safari &
            {
                @include transitions(opacity 500ms $easeOutCubic , -webkit-transform 800ms $easeOutQuad);
            }
            span
            {
                @include absoluteLeft(50%,50%);
                color:                  $blue-light-01;
                display:                block;
                font:                   normal 1.3em $simplo;
                letter-spacing:         .25px;
                text-transform:         uppercase;
                @include transitions( transform 500ms $easeOutQuad);
                @include translate(-50%,-5%);
                .safari &
                {
                    @include transitions( -webkit-transform 500ms $easeOutQuad);
                }
            }
        }
        &:before,&::before
        {
            @include absoluteLeft(50%,50%);
            background: rgb(67,148,248); /* Old browsers */
            background: -moz-linear-gradient(left,  rgba(67,148,248,1) 0%, rgba(31,42,68,1) 100%); /* FF3.6+ */
            background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(67,148,248,1)), color-stop(100%,rgba(31,42,68,1))); /* Chrome,Safari4+ */
            background: -webkit-linear-gradient(left,  rgba(67,148,248,1) 0%,rgba(31,42,68,1) 100%); /* Chrome10+,Safari5.1+ */
            background: -o-linear-gradient(left,  rgba(67,148,248,1) 0%,rgba(31,42,68,1) 100%); /* Opera 11.10+ */
            background: -ms-linear-gradient(left,  rgba(67,148,248,1) 0%,rgba(31,42,68,1) 100%); /* IE10+ */
            background: linear-gradient(to right,  rgba(67,148,248,1) 0%,rgba(31,42,68,1) 100%); /* W3C */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4394f8', endColorstr='#1f2a44',GradientType=1 ); /* IE6-9 */
            @include border-radius($community-grid-size);
            @include backface-visibility(hidden);
            content:                " ";
            display:                block;
            height:                 $community-grid-size + 7;
            @include translate(-50%,-50%);
            width:                  $community-grid-size + 7;
            z-index:                0;
        }
        &:hover
        {
            .gen__bg
            {
                @include transform(.97,0,-50%,-50%,0,0);
            }
            .text
            {
                @include opacity(1);
                span
                {
                    @include translate(-50%,-50%);
                }
            }
        }
    }
    &:nth-child(4n+2)
    {
        right:              -$community-grid-gutter;

    }
    &:nth-child(4n+3)
    {
        right:              -$community-grid-gutter*2;
    }
    &:nth-child(4n+4)
    {
        right:              -$community-grid-gutter*3;
    }
}


// SMALL SCREENS
//==============
@media only screen and (max-width : 980px)
{
    .community-collection
    {

        padding:                20px $community-grid-gutter 0 0;
    }
    .community-collection__item
    {
        width:                  50%;
        &:nth-child(4n+1)
        {
            right:              0;
        }
        &:nth-child(4n+2)
        {
            right:              -$community-grid-gutter;
        }
        &:nth-child(4n+3)
        {
            right:              0;
        }
        &:nth-child(4n+4)
        {
            right:              -$community-grid-gutter;
        }
    }
}
