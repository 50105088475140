@charset "utf-8";
@import "../../node_modules/mixins-sass/src/mixins.scss";
@import "base.param.scss";
@import "sprite.scss";

// GENERIC
//========
.gen__bg
{
    @include absoluteLeft(0,0);
    @include backgroundSize(cover);
    background-repeat:      no-repeat;
    background-position:    center center;
    display:                block;
    height:                 100%;
    width:                  100%;
    img {
        display:                none;
    }
}

.gen__button--apply
{
    background:             $white;
    color:                  $blue-dark-01;
    display:                block;
    font:                   normal 1.2em $simplo;
    height:                 100%;
    letter-spacing:         1.5px;
    overflow:               hidden;
    padding:                0 15px;
    position:               relative;
    text-transform:         uppercase;
    @include transitions(color 300ms $easeOutCubic);
    z-index:                0;
    #main-banner &
    {
        @include transitions(color 50ms linear);
    }
    .text
    {
        display:                block;
        @include translate(0,0);
        @include transitions(all 400ms $easeOutExpo);
    }
    &:after
    {
        @include absoluteLeft(0,0);
        background:             $blue-light-01;
        content:                " ";
        display:                block;
        height:                 100%;
        @include translate(0,-100%);
        @include transitions(all 300ms $easeOutQuint);
        width:                  100%;
        z-index:                -1;
    }
    &:hover
    {
        color:                  $white;
        .text
        {
            @include translate(0,5px);
        }
        &:after
        {
            @include translate(0,0);
        }
    }
}

.gen__button--cta
{
    background:             $blue-dark-01;
    color:                  $white;
    @include inlineBlock;
    font:                   normal 1.2em $simplo;
    height:                 100%;
    letter-spacing:         1.5px;
    overflow:               hidden;
    padding:                15px;
    position:               relative;
    text-transform:         uppercase;
    @include transitions(color 300ms $easeOutCubic);
    z-index:                0;
    .text
    {
        display:                block;
        @include translate(0,0);
        @include transitions(all 400ms $easeOutExpo);
    }
    &:after
    {
        @include absoluteLeft(0,-100%);
        background:             $blue-light-01;
        content:                " ";
        display:                block;
        height:                 100%;
        @include transitions(top 300ms $easeOutQuint);
        width:                  100%;
        z-index:                -1;
    }
    &:hover
    {
        color:                  $white;
        .text
        {
            @include translate(0,5px);
        }
        &:after
        {
            top: 0;
        }
    }
}

.gen__button--contextual
{
    color:                  $blue-dark-01;
    @include inlineBlock;
    font:                   normal 1.4em $simplo;
    letter-spacing:         1.5px;
    position:               relative;
    text-transform:         uppercase;
    @include transitions(color 300ms $easeOutCubic);
    &:before
    {
        @include absoluteLeft(-20px,1px);
        @include callIcomoon;
        content:                "\e605";
        font-size:              11px;
        @include transitions(left 300ms $easeOutQuad);
    }
    &:after
    {
        @include absoluteLeft(0,auto);
        background:             $blue-light-01;
        bottom:                 0;
        content:                " ";
        display:                block;
        height:                 1px;
        @include scaleXY(0,1);
        @include transform-origin(0 0);
        @include transitions(all 300ms $easeInOutQuart);
        @include transition-delay(100ms);
        width:                  100%;
    }
    &:hover
    {
        color:                  $blue-light-01;
        &:before
        {
            left:                   -16px;
        }
        &:after
        {
            @include scaleXY(1,1);
        }
    }
}

.gen__button--simple
{
    color:                  $blue-dark-01;
    @include inlineBlock;
    font:                   normal 1.4em $simplo;
    letter-spacing:         1.5px;
    position:               relative;
    text-transform:         uppercase;
}


.gen__button--play
{
    border:                 3px solid rgba(255,255,255,1);
    @include border-radius($button-play-size);
    height:                 $button-play-size;
    @include inlineBlock;
    @include transitions(border-color 300ms $easeOutCubic);
    width:                  $button-play-size;
    .icon
    {
        color:                  $white;
        display:                block;
        font-size:              35px;
        line-height:            $button-play-size;
        position:               relative;
        left:                   15px;
        &:after,&::after
        {
            @include absoluteLeft(20px,20px);
            border-style:           solid;
            border-width:           11px 0 11px 19px;
            border-color:           transparent transparent transparent $white;
            clip:                   rect(0,0,22px,0);
            content:                " ";
            display:                block;
            height:                 0;
            @include transitions(clip 300ms $easeOutCubic);
            width:                  0;
        }
    }
    &:hover
    {
        border-color:           rgba(255,255,255,.6);
        .icon
        {
            &:after,&::after
            {
                clip:                   rect(0,19px,22px,0);
            }
        }
    }
}

.gen__centerContext
{
    display:                table-cell;
    vertical-align:         middle;
}

.gen__context
{
    margin:                 0 auto;
    width:                  60vw;
}

.gen__description--big
{
    color:                  $grey-01;
    font:                   $openLight 1.8em/1.4 $open;
    a
    {
        color:                  $blue-dark-01;
        @include transitions(color 300ms $easeOutCubic);
        &:hover
        {
            color:                  $blue-light-01;
        }
    }
    .emphasis
    {
        color:                  $blue-dark-01;
        display:                block;
        font:                   $openSemi 1.2em/1.4 $open;
        margin:                 35px 0;
    }
}

.gen__icon--h1
{
    color:                  $grey-01;
    font-size:              30px;
    margin:                 20px 0;
}

.gen__share
{
    height:                 160px;
    margin:                 0 auto;
    position:               relative;
    width:                  240px;
    z-index:                1;
    .button
    {
        @include absoluteLeft(50%,auto);
        background:             $blue-dark-01;
        @include border-radius(50px);
        border:                 3px solid $white;
        bottom:                 -($share-button-size/2);
        display:                block;
        height:                 $share-button-size;
        line-height:            $share-button-size;
        text-align:             center;
        @include translate(-50%,0);
        @include transitions(background-color 300ms $easeOutCubic);
        width:                  $share-button-size;
        z-index:                1;
        .icon
        {
            color:                  $white;
            font-size:              30px;
            &.icon-close
            {
                display:                none;
                line-height:            47px;
            }
            &.icon-arrow-share
            {
                display:                block;
                line-height:            40px;
            }
        }
    }
    .collection
    {
        @include absoluteLeft(0,0);
        height:                 100%;
        list-style:             none;
        position:               relative;
        width:                  100%;
        z-index:                0;
        li
        {
            @include absoluteLeft(97px,134px);
            display:                block;
            height:                 40px;
            position:               absolute;
            text-align:             center;
            @include transitions(all 700ms $easeInOutQuart);
            width:                  40px;
            a
            {
                background:             $blue-dark-01;
                border:                 3px solid $white;
                @include border-radius($share-button-size);
                color:                  $white;
                display:                block;
                font-size:              25px;
                line-height:            40px;
                height:                 100%;
                @include transitions(background-color 300ms $easeOutCubic);
                width:                  100%;
                &.icon-mail
                {
                    font-size:              20px;
                }
                &.icon-google
                {
                    font-size:              20px;
                }
                &.icon-social-linkedin
                {
                    font-size:              30px;
                }
                &:hover
                {
                    background:             $blue-light-01;
                }
            }
            &:nth-child(1)
            {
                @include transition-delay(0ms);

            }
            &:nth-child(2)
            {
                @include transition-delay(50ms);
            }
            &:nth-child(3)
            {
                @include transition-delay(100ms);
            }
            &:nth-child(4)
            {
                @include transition-delay(150ms);
            }
            &:nth-child(5)
            {
                @include transition-delay(200ms);
            }
        }
    }
    &:hover
    {
        .button
        {
            background:             $blue-light-01;
        }
        .icon-close
        {
            display:                block !important;
        }
        .icon-arrow-share
        {
            display:                none !important;
        }
        .collection
        {
            li
            {
                &:nth-child(1)
                {
                    left:                   0;
                    top:                    100px;
                }
                &:nth-child(2)
                {
                    left:                   37px;
                    top:                    50px;
                }
                &:nth-child(3)
                {
                    left:                   97px;
                    top:                    30px;
                }
                &:nth-child(4)
                {
                    left:                   157px;
                    top:                    50px;
                }
                &:nth-child(5)
                {
                    left:                   190px;
                    top:                    100px;
                }
            }
        }
    }
}

.gen__subtitle--big
{
    color:                  $white;
    font:                   $openLight 1.4em/1.2 $simplo;
    letter-spacing:         1.5px;
    text-transform:         uppercase;
}

.gen__title--big
{
    color:                  $white;
    font:                   $openLight 3.8em/1.2 $open;
    letter-spacing:         .25px;
    strong
    {
        font-weight:            $openSemi;
    }
}

.gen__videobg
{
    @include absoluteLeft(50%,50%);
    height:                     auto;
    min-height:                 100%;
    min-width:                  100%;
    @include translate(-50%,-50%);
    width:                      auto;
    z-index:                    0;
}

// JS ELEMENTS
//============
.jsTracker--lightAmbiant,.jsTracker--darkAmbiant,.jsTracker--sticky
{
    content:                " ";
    height:                 0.1px;
    display:                block;
    overflow:               hidden;
    width:                  100%;
}

// PARTNERS
//=========
.logo-h1
{
    display:                block;
    @include sprite($partner_h1-bw);
    position:               relative;
    &:before
    {
        @include absoluteLeft(0,0);
        content:                " ";
        display:                block;
        @include opacity(0);
        @include sprite($partner_h1-color);
    }
}
.logo-lime
{
    display:                block;
    @include sprite($partner_lime-bw);
    position:               relative;
    &:before
    {
        @include absoluteLeft(0,0);
        content:                " ";
        display:                block;
        @include opacity(0);
        @include sprite($partner_lime-color);
    }
}
.logo-access
{
    display:                block;
    @include sprite($partner_access-bw);
    position:               relative;
    &:before
    {
        @include absoluteLeft(0,0);
        content:                " ";
        display:                block;
        @include opacity(0);
        @include sprite($partner_access-color);
    }
}
.logo-pch
{
    display:                block;
    @include sprite($partner_pch-bw);
    position:               relative;
    &:before
    {
        @include absoluteLeft(0,0);
        content:                " ";
        display:                block;
        @include opacity(0);
        @include sprite($partner_pch-color);
    }
}
.logo-tns
{
    display:                block;
    @include sprite($partner_tns-bw);
    position:               relative;
    &:before
    {
        @include absoluteLeft(0,0);
        content:                " ";
        display:                block;
        @include opacity(0);
        @include sprite($partner_tns-color);
    }
}
.logo-fab
{
    display:                block;
    @include sprite($partner_fab-bw);
    position:               relative;
    &:before
    {
        @include absoluteLeft(0,0);
        content:                " ";
        display:                block;
        @include opacity(0);
        @include sprite($partner_fab-color);
    }
}

// OVERLAY
//========
.overlay
{
    @include absoluteLeft(0,0);
    background:             rgba(0,0,0,.4);
    height:                 100%;
    @include opacity(0);
    position:               fixed;
    pointer-events:         none;
    @include transitions(opacity 500ms $easeOutCubic);
    width:                  100%;
    z-index:                4;
    &.is--active
    {
        @include opacity(1);
        pointer-events:         auto;
        .ie9 &,.ie10 &
        {
            display:                block;
        }
    }
    .ie9 &,.ie10 &
    {
        display:                none;
    }
}